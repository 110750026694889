.Button {
  margin-top: 1em;
  font: inherit;
  position: relative;
  border: 0;
  border-radius: 1em;
  padding: 1em 2em;
  text-decoration: none;
  background: none;
  width: fit-content;

  :enabled {
    cursor: pointer;
    color: var(--primary);

    &--secondary {
      text-decoration: underline;
    }
  }

  :disabled {
    color: #6d5e4d;
  }

  &--primary {
    text-decoration: none;

    &:enabled {
      background: rgba(var(--secondary-rgb), 0.75);

      &:hover {
        box-shadow: 0.1rem 0.1rem var(--secondary);
      }

      &:active {
        box-shadow: none;
        transform: translate(0.1rem, 0.1rem);
      }
    }

    &:disabled {
      background: #e2dfcc;
    }
  }
}
