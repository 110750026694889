@font-face {
  font-family: "Hansen Grotesque";
  src: url("../fonts/hansen-grotesque-400.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Hansen Grotesque";
  src: url("../fonts/hansen-grotesque-600.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Hansen Grotesque";
  src: url("../fonts/hansen-grotesque-700.ttf") format("truetype");
  font-weight: 700;
}
