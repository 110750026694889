/*
 * This is a manifest file that'll be compiled into application.css, which will include all the files
 * listed below.
 *
 * Any CSS and SCSS file within this directory, lib/assets/stylesheets, or any plugin's
 * vendor/assets/stylesheets directory can be referenced here using a relative path.
 *
 * You're free to add application-wide styles to this file and they'll appear at the bottom of the
 * compiled file so the styles you add here take precedence over styles defined in any other CSS/SCSS
 * files in this directory. Styles in this file should be added after the last require_* statement.
 * It is generally better to create a new file per style scope.
 */
@import "fonts";

:root {
  --primary: #36244d;
  --secondary: #dcbcfd;
  --secondary-rgb: 220, 188, 253;
  --light-red: #fcf4f0;
  --light-green: #e0f9f0;
  --light-yellow: #fcfaf0;
  --dark-red: #f2d7ca;
  --dark-green: #cbebdb;
}

html {
  background-color: var(--light-yellow);
  color: var(--primary);
  font-size: 16px;
  font-family: "Hansen Grotesque", arial, sans-serif;
}

body {
  margin: 0;
  height: 100%;
  padding-bottom: 40px;
}

footer {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 80%;
}

.container {
  width: 800px;
  max-width: calc(100% - 20px);
  margin: 5em auto;
}

.float-right {
  margin-left: 1em;
  float: right;
}
